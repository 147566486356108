import styled from "styled-components";
import { useTranslation } from "react-i18next";
import bild_1 from "../images/Aussenansicht Sommer.jpg";
import bild_2 from "../images/Badebottich.jpg";
import bild_3 from "../images/Käserei 3.jpg";
import bild_4 from "../images/Food Vivanda 4.jpg";
import logo_inlain from "../images/IN_LAIN_Logo_RGB.png";

const MainContentWrapper = styled.div`
  padding-bottom: 32px;
  padding: 24px 32px;
  padding-top: 80px;
  color: white;
`;

const Titel = styled.div`
  font-family: Fjalla One;
  font-size: 36px;
  font-weight: 400;
  line-height: 44px;
  letter-spacing: 0.2px;

  @media (min-width: 750px) {
    font-family: Fjalla One;
    font-size: 48px;
    font-weight: 400;
    line-height: 56px;
    letter-spacing: 0.2px;
  }
`;

const Text = styled.div`
  font-family: Raleway;
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.3px;

  @media (min-width: 750px) {
    font-family: Raleway;
    font-size: 28px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0.3px;
  }
`;

const Wrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  cursor: pointer;
  width: 100%;
  position: relative;

  @media (min-width: 760px) {
    width: 47%;
  }

  @media (min-width: 1600px) {
    width: 23%;
  }
`;

const CardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Bild = styled.img`
  width: 150px;
  margin: 10px 0px;
  cursor: pointer;
`;

const TextLink = styled(Text)`
  cursor: pointer;
  text-decoration: underline;
  margin-top: 16px;
`;
const Preise = ({ handlePreiseBackLinkClick, handleHotelLinkClick }) => {
  const { t } = useTranslation();
  return (
    <MainContentWrapper>
      <Titel>{t("Preise_Titel_1")}</Titel>
      <Text dangerouslySetInnerHTML={{ __html: t("Preise_Text_1") }}></Text>
      <Titel>{t("Preise_Titel_2")}</Titel>

      <CardWrapper>
        <Wrapper>
          <img src={bild_1} alt="" style={{ width: "100%" }} />
        </Wrapper>
        <Wrapper>
          <img src={bild_2} alt="" style={{ width: "100%" }} />
        </Wrapper>
        <Wrapper>
          <img src={bild_3} alt="" style={{ width: "100%" }} />
        </Wrapper>
        <Wrapper>
          <img src={bild_4} alt="" style={{ width: "100%" }} />
        </Wrapper>
      </CardWrapper>
      <TextLink onClick={handleHotelLinkClick}>{t("Hotel_Link")}</TextLink>
      <div>
        <Bild src={logo_inlain} onClick={handleHotelLinkClick} />
      </div>
      <TextLink onClick={handlePreiseBackLinkClick}>
        {t("Preise_Back_Link")}
      </TextLink>
    </MainContentWrapper>
  );
};

export default Preise;
