import React from "react";
import styled from "styled-components";
import logo from "../images/logo.svg";

const NavigationWrapper = styled.div`
  background-color: #ffffff;
  padding-left: 2rem;
  padding-right: 2rem;
  color: white;
  border-bottom: 1px solid white;
  height: 72px;

  @media (min-width: 1200px) {
    height: 160px;
    padding-left: 8rem;
    padding-right: 8rem;
  }
`;

const NavigationContent = styled.div`
  padding: 0 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 72px;
  box-sizing: border-box;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-transform: uppercase;

  @media (min-width: 1200px) {
    height: 160px;
  }
`;

const SprachLinkWrapper = styled.div`
  display: flex;
`;

const SprachLink = styled.div`
  margin-right: 24px;
  font-family: futura-pt, sans-serif;
  font-size: 13px;
  font-weight: 800;
  color: #121212;
  cursor: pointer;
  padding-top: 12px;

  @media (min-width: 1200px) {
    padding-top: 0px;
  }

  &:hover {
    color: #687c35;
  }
`;

const Bild = styled.img`
  height: 32px;
  cursor: pointer;

  @media (min-width: 1200px) {
    height: 48px;
  }
`;

export const Navigation = () => {
  const handleClick = (sprache) => (event) => {
    const newURL =
      window.location.protocol +
      "//" +
      window.location.host +
      process.env.PUBLIC_URL +
      sprache;
    window.location.href = newURL;
  };

  return (
    <NavigationWrapper>
      <NavigationContent>
        {/*         <SprachLinkWrapper>
          <SprachLink onClick={handleClick("/de")}>DE</SprachLink>
          <SprachLink onClick={handleClick("/fr")}>RM</SprachLink>
        </SprachLinkWrapper> */}
        <Bild
          onClick={() => {
            window.open("https://www.lesa.ch/");
          }}
          src={logo}
          alt=""
        />
      </NavigationContent>
    </NavigationWrapper>
  );
};

export default Navigation;
