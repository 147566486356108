import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { RadioBox } from "./RadioBox";
import { RadioGroup } from "./RadioGroup";
import { Checkbox } from "./Checkbox";
import { useEffect, useState } from "react";
import { TextInput } from "./TextInput";
import { SelectInput } from "./SelectInput";
import {
  optionItemsTage,
  optionItemsMonate,
  optionItemsJahre,
  optionItemsLaender
} from "../helper/optionsItems";

const MainContentWrapper = styled.div`
  padding-bottom: 0px;
  padding: 24px 32px;
  padding-top: 0px;
  color: white;
`;

const Form = styled.div``;

const Paragraph = styled.div`
  font-family: Raleway;
  font-size: 16px;
  letter-spacing: 0.3px;
  margin-bottom: 16px;
`;

const Formular = ({ onChangeForm }) => {
  const initialFormData = {
    anrede: "",
    vorname: "",
    nachname: "",
    email: "",
    tag: "",
    monat: "",
    jahr: "",
    teilnahmebedingungen: false,
  };

  // Use State Hooks **************************************************
  const [teilnahmebedingungen, setTeilnahmebedingungen] = useState(false);
  const [alter, setAlter] = useState(false);
  const [form, setForm] = useState(initialFormData);

  const { t, i18n } = useTranslation();
  const [radioGroup, setRadioGroup] = useState([
    {
      name: "0001",
      selected: false,
      label: t("Frau"),
      sprache: i18n.language,
    },
    {
      name: "0002",
      selected: false,
      label: t("Herr"),
      sprache: i18n.language,
    },
  ]);

  useEffect(() => {
    onChangeForm && onChangeForm(form);
  }, [form]);

  const handleClickTeilnahmebedingungen = () => {
    setTeilnahmebedingungen(!teilnahmebedingungen);
    setForm({
      ...form,
      teilnahmebedingungen: !teilnahmebedingungen,
    });
  };

  const handleClickAlter = () => {
    setAlter(!alter);
    setForm({
      ...form,
      alter: !alter,
    });
  };  

  const handleClickTeilnahmebedingungenLink = (event) => {
    event.stopPropagation();
    window.open(t("Teilnahmebedingungen_Link"));
  };

  // Event Handler *****************************************************
  const handleClickRadioButton = (name) => {
    //console.log(name);
    const newArray = radioGroup.map((item) => {
      item.name === name ? (item.selected = true) : (item.selected = false);
      return item;
    });
    setRadioGroup(newArray);
    setForm({
      ...form,
      anrede: name,
    });
  };

  // Form change Handler
  // bei select Inputs hat es einen zusätzlichen Event Parameter (payload)
  const handleChange = (event, payload) => {
    if (payload) {
      // selects
      const { value, name, checked } = payload;
      //console.log(value, name, checked);
      setForm({
        ...form,
        [name]: value ? value : checked,
      });
    } else {
      event.target.value = jsUcfirst(event.target.value);
      //console.log(event.target.value);
      if (event.target.name === "email") {
        event.target.value = event.target.value.toLowerCase();
      }
      setForm({
        ...form,
        [event.target.name]: event.target.value,
      });
      if (event.target.name === "teilnahmebedingungen") {
        setForm({
          ...form,
          [event.target.name]: !form.teilnahmebedingungen,
        });
      }
      if (event.target.name === "plz") {
        let zahl;
        if (event.target.value > 9999) {
          zahl = event.target.value.toString().substring(0, 4);
        }
        zahl && (event.target.value = parseInt(zahl));
        setForm({
          ...form,
          [event.target.name]: event.target.value,
        });
      }
    }
  };

  const handleOnBlur = (event) => {
    event.target.value = event.target.value.toLowerCase();
    let nameCapitalized =
      event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
    nameCapitalized = nameCapitalized.replace(/(^|[\s-])\S/g, function (match) {
      return match.toUpperCase();
    });
    event.target.value = nameCapitalized;
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  // Erster Buchstabe uppercase
  function jsUcfirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <MainContentWrapper>
      <Form>
        {/*         <RadioGroup label={"*" + t("Anrede")}>
          {radioGroup.map((item) => {
            //console.log(item);
            return (
              <RadioBox
                key={item.name}
                label={item.label}
                name={item.name}
                selected={item.selected}
                onClickRadio={handleClickRadioButton}
              ></RadioBox>
            );
          })}
        </RadioGroup> */}
        <TextInput
          label={"*" + t("Vorname")}
          name="vorname"
          value={form.vorname}
          onChange={handleChange}
          onBlur={handleOnBlur}
        ></TextInput>
        <TextInput
          label={"*" + t("Nachname")}
          name="nachname"
          value={form.nachname}
          onChange={handleChange}
          onBlur={handleOnBlur}
        ></TextInput>
        {/*         <SelectInput
          name="tag"
          value={form.tag}
          onChange={handleChange}
          options={optionItemsTage}
          label={"*" + t("Tag")}
          placeholder={t("Tag")}
        ></SelectInput>
        <SelectInput
          name="monat"
          value={form.monat}
          onChange={handleChange}
          options={optionItemsMonate}
          label={"*" + t("Monat")}
          placeholder={t("Monat")}
        ></SelectInput>
        <SelectInput
          name="jahr"
          value={form.jahr}
          onChange={handleChange}
          options={optionItemsJahre}
          label={"*" + t("Jahr")}
          placeholder={t("Jahr")}
        ></SelectInput> */}
        <TextInput
          label={"*" + t("E-Mail")}
          name="email"
          value={form.email}
          onChange={handleChange}
        ></TextInput>
        <SelectInput
          name="land"
          value={form.land}
          onChange={handleChange}
          options={optionItemsLaender}
          label={"*" + t("Land")}
          placeholder={t("Land")}
        ></SelectInput>{" "}
        <Paragraph>{t("pflichtfeld")}</Paragraph>
        <Checkbox
          checked={alter}
          onClick={handleClickAlter}
          label={t("tb.alter")}
          link={""}
          //onClickLink={handleClickTeilnahmebedingungenLink}
        ></Checkbox>
        <Checkbox
          checked={teilnahmebedingungen}
          onClick={handleClickTeilnahmebedingungen}
          label={t("tb.teil.1")}
          link={t("tb.teil.2")}
          onClickLink={handleClickTeilnahmebedingungenLink}
        ></Checkbox>
      </Form>
    </MainContentWrapper>
  );
};

export default Formular;
