import React from "react";
import styled from "styled-components";
import { RadioButtonOff } from "@styled-icons/evaicons-solid/RadioButtonOff";
import { RadioButtonOn } from "@styled-icons/evaicons-solid/RadioButtonOn";

const RadioBoxWrapper = styled.div`
  padding-right: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: Raleway;
  font-size: 16px;
  letter-spacing: 0.3px;
  color: #ddd;

  @media (min-width: 750px) {
    /* font-size: 18px; */
    font-size: 20px;
    letter-spacing: 0.3px;
    line-height: 32px;
  }

  &:hover {
    color: white; // <Thing> when hovered
    transition: 0.3s;
  }
`;

const RadioButtonOffStyled = styled(RadioButtonOff)`
  color: white;
  /* min-width: 28px; */
  width: 28px;
  height: 28px;
`;

const RadioButtonOnStyled = styled(RadioButtonOn)`
  color: white;
  /* min-width: 28px; */
  width: 28px;
  height: 28px;
`;

const Label = styled.div`
  margin-left: 8px;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 32px;
  color: ${(props) => props.selected === true && "white"};

  &:hover {
    color: white; // <Thing> when hovered
    transition: 0.3s;
  }
`;

export const RadioBox = ({ name, selected, onClickRadio, label }) => {
  return (
    <div
      onClick={() => {
        onClickRadio(name);
      }}
    >
      <RadioBoxWrapper>
        {selected ? (
          <RadioButtonOnStyled></RadioButtonOnStyled>
        ) : (
          <RadioButtonOffStyled></RadioButtonOffStyled>
        )}
        <Label selected={selected}>{label}</Label>
      </RadioBoxWrapper>
    </div>
  );
};
