import React from "react";
import styled from "styled-components";

const RadioGroupWrapper = styled.div`
  /* display: flex; */
  margin-bottom: 32px;
  margin-top: 16px;
`;

const Label = styled.label`
  display: block;
  text-transform: uppercase;
  color: white;
  font-weight: 800;
  margin-bottom: 18px;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin-bottom: 3px;

  @media (min-width: 750px) {
    margin-bottom: 6px;
    font-size: 18px;
    line-height: 40px;
  }
`;

const BigLabel = styled(Label)`
  font-size: 26px;
`;

export const RadioGroup = ({ children, label }) => {
  return (
    <div>
      <Label>{label}</Label>
      <RadioGroupWrapper>
        {React.Children.map(children, (child) => {
          return <div style={{ flex: 1 }}>{child}</div>;
        })}
      </RadioGroupWrapper>
    </div>
  );
};
