import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const FooterContentWrapper = styled.div`
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 32px;
  background-color: white;

  @media (min-width: 1200px) {
    padding-left: 128px;
    padding-right: 128px;
  }
`;

const FooterTitel = styled.div`
  font-family: Raleway;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.3px;
  margin-bottom: 16px;
  color: #121212;
`;

const FooterLink = styled.div`
  cursor: pointer;
  font-family: Raleway;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: #687c35;
  text-decoration: none;
  margin-bottom: 8px;
`;

const TextWrapper = styled.div`
  padding-top: 80px;
  padding-bottom: 128px;

  @media (min-width: 750px) {
    padding-left: 32px;
  }
`;

const FooterContent = () => {
  const { t } = useTranslation();
  return (
    <FooterContentWrapper>
      <TextWrapper>
        <FooterTitel>{t("Quicklinks")}</FooterTitel>
        <FooterLink
          onClick={() => {
            window.open(t("Datenschutzbestimmungen_Link"));
          }}
        >
          {t("Datenschutzbestimmungen")}
        </FooterLink>
        <FooterLink
          onClick={() => {
            window.open(t("Teilnahmebedingungen_Link"));
          }}
        >
          {t("Teilnahmebedingungen")}
        </FooterLink>
        <FooterLink
          onClick={() => {
            window.open(t("Nutzungsbedingungen_Link"));
          }}
        >
          {t("Nutzungsbedingungen")}
        </FooterLink>
      </TextWrapper>
    </FooterContentWrapper>
  );
};

export default FooterContent;
