import React from "react";
import styled from "styled-components";

const Label = styled.label`
  display: block;
  text-transform: uppercase;
  color: white;
  font-weight: 600;
  font-family: Raleway;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin-bottom: 3px;

  @media (min-width: 750px) {
    font-size: 18px;
    margin-bottom: 6px;
  }
`;

const Input = styled.input`
  font-family: Raleway;
  font-size: 18px;
  padding: 18px 24px 17px;
  font-size: 16px;
  border-width: 0px;
  border-bottom-width: 1px;
  border-color: #121212;
  background-color: #ffffff;
  color: #000000;
  border-style: solid;
  border-radius: 3px;
  width: 100%;
  margin-bottom: 16px;

  &:focus {
    outline: none;
    border-bottom-width: 2px;
  }
`;

export const TextInput = ({
  name,
  value,
  onChange,
  onBlur,
  label,
  placeholder,
  type = "text",
}) => {
  return (
    <div>
      <Label>{label}</Label>
      <Input
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        type={type}
        placeholder={placeholder}
      />
    </div>
  );
};
