import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { RadioBox } from "./RadioBox";
import { RadioGroup } from "./RadioGroup";
import { useEffect, useState } from "react";

const MainContentWrapper = styled.div`
  padding-bottom: 32px;
  padding: 24px 32px;
  padding-top: 80px;
  color: white;
`;

const Titel = styled.div`
  font-family: "Fjalla One", sans-serif;
  font-size: 36px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0.5px;

  @media (min-width: 750px) {
    font-size: 52px;
    line-height: 58px;
  }
`;

const Text = styled.div`
  font-family: Raleway;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.3px;
  margin-top: 32px;

  @media (min-width: 750px) {
    font-size: 22px;
    line-height: 28px;
  }
`;

const Fragen = ({ onSelectOption }) => {
  const { t, i18n } = useTranslation();
  const initialData = {
    antwort_1_1: false,
    antwort_1_2: false,
    antwort_1_3: false,
    antwort_2_1: false,
    antwort_2_2: false,
    antwort_2_3: false,
    antwort_3_1: false,
    antwort_3_2: false,
    antwort_3_3: false,
  };
  const [antworten, setAntworten] = useState(initialData);

  const [radioGroup_1, setRadioGroup_1] = useState([
    {
      name: "Antwort_1_1",
      selected: false,
      label: t("Antwort_1_1"),
      sprache: i18n.language,
    },
    {
      name: "Antwort_1_2",
      selected: false,
      label: t("Antwort_1_2"),
      sprache: i18n.language,
    },
/*     {
      name: "Antwort_1_3",
      selected: false,
      label: t("Antwort_1_3"),
      sprache: i18n.language,
    }, */
  ]);
  const [radioGroup_2, setRadioGroup_2] = useState([
    {
      name: "Antwort_2_1",
      selected: false,
      label: t("Antwort_2_1"),
      sprache: i18n.language,
    },
    {
      name: "Antwort_2_2",
      selected: false,
      label: t("Antwort_2_2"),
      sprache: i18n.language,
    },
    {
      name: "Antwort_2_3",
      selected: false,
      label: t("Antwort_2_3"),
      sprache: i18n.language,
    },
  ]);
  const [radioGroup_3, setRadioGroup_3] = useState([
    {
      name: "Antwort_3_1",
      selected: false,
      label: t("Antwort_3_1"),
      sprache: i18n.language,
    },
    {
      name: "Antwort_3_2",
      selected: false,
      label: t("Antwort_3_2"),
      sprache: i18n.language,
    },
    {
      name: "Antwort_3_3",
      selected: false,
      label: t("Antwort_3_3"),
      sprache: i18n.language,
    },
  ]);

  // Event Handler *****************************************************
  const handleClickRadioButton_1 = (name) => {
    //console.log(name);
    const newArray = radioGroup_1.map((item) => {
      item.name === name ? (item.selected = true) : (item.selected = false);
      return item;
    });
    setRadioGroup_1(newArray);
    setAntworten({
      ...antworten,
      antwort_1_1: newArray[0].selected,
      antwort_1_2: newArray[1].selected,
/*       antwort_1_3: newArray[2].selected, */
    });
  };
  const handleClickRadioButton_2 = (name) => {
    //console.log(name);
    const newArray = radioGroup_2.map((item) => {
      item.name === name ? (item.selected = true) : (item.selected = false);
      return item;
    });
    setRadioGroup_2(newArray);
    setAntworten({
      ...antworten,
      antwort_2_1: newArray[0].selected,
      antwort_2_2: newArray[1].selected,
      antwort_2_3: newArray[2].selected,
    });
  };
  const handleClickRadioButton_3 = (name) => {
    //console.log(name);
    const newArray = radioGroup_3.map((item) => {
      item.name === name ? (item.selected = true) : (item.selected = false);
      return item;
    });
    setRadioGroup_3(newArray);
    setAntworten({
      ...antworten,
      antwort_3_1: newArray[0].selected,
      antwort_3_2: newArray[1].selected,
      antwort_3_3: newArray[2].selected,
    });
  };

  useEffect(() => {
    console.log("Use Effect");
    console.log(antworten);
    onSelectOption && onSelectOption(antworten);
  }, [antworten]);

  return (
    <MainContentWrapper>
      <Titel>{t("Fragen")}</Titel>
      <Text>{t("Frage_1")}</Text>
      <RadioGroup label={""}>
        {radioGroup_1.map((item) => {
          //console.log(item);
          return (
            <RadioBox
              key={item.name}
              label={item.label}
              name={item.name}
              selected={item.selected}
              onClickRadio={handleClickRadioButton_1}
            ></RadioBox>
          );
        })}
      </RadioGroup>
{/*       <Text>{t("Frage_2")}</Text>
      <RadioGroup label={""}>
        {radioGroup_2.map((item) => {
          //console.log(item);
          return (
            <RadioBox
              key={item.name}
              label={item.label}
              name={item.name}
              selected={item.selected}
              onClickRadio={handleClickRadioButton_2}
            ></RadioBox>
          );
        })}
      </RadioGroup>
      <Text>{t("Frage_3")}</Text>
      <RadioGroup label={""}>
        {radioGroup_3.map((item) => {
          //console.log(item);
          return (
            <RadioBox
              key={item.name}
              label={item.label}
              name={item.name}
              selected={item.selected}
              onClickRadio={handleClickRadioButton_3}
            ></RadioBox>
          );
        })}
      </RadioGroup>
      <Text>{t("Fragen_Video")}</Text> */}
    </MainContentWrapper>
  );
};

export default Fragen;
