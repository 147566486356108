import styled from "styled-components";
import { useTranslation } from "react-i18next";

const MainContentWrapper = styled.div`
  padding-bottom: 32px;
  padding: 24px 32px;
  padding-top: 80px;
  color: white;
`;

const Titel = styled.div`
  font-family: Fjalla One;
  font-size: 36px;
  font-weight: 400;
  line-height: 44px;
  letter-spacing: 0.2px;

  @media (min-width: 750px) {
    font-family: Fjalla One;
    font-size: 48px;
    font-weight: 400;
    line-height: 56px;
    letter-spacing: 0.2px;
  }
`;

const Text = styled.div`
  font-family: Raleway;
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.3px;

  @media (min-width: 750px) {
    font-family: Raleway;
    font-size: 28px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0.3px;
  }
`;

const TextLink = styled(Text)`
  cursor: pointer;
  text-decoration: underline;
`;
const Intro = ({ handleClickPreise }) => {
  const { t } = useTranslation();
  return (
    <MainContentWrapper>
      <Titel>{t("Intro_Titel")}</Titel>
      <Text>{t("Intro_Text")}</Text>
      <TextLink onClick={handleClickPreise}>{t("Intro_Link")}</TextLink>
    </MainContentWrapper>
  );
};

export default Intro;
