import React from "react";
import { Square } from "@styled-icons/feather/Square";
import { XSquare } from "@styled-icons/feather/XSquare";
import styled from "styled-components";

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-family: Raleway;
  font-size: 18px;
  letter-spacing: 0.3px;
  cursor: pointer;
  margin-bottom: 0px;

  @media (min-width: 750px) {
    font-size: 18px;
  }
`;

const Checked = styled(XSquare)`
  color: white;
  width: 32px;
`;

const NotChecked = styled(Square)`
  color: white;
  width: 32px;
`;

const Label = styled.div`
  margin-left: 8px;
  color: white;
`;

const Label_2 = styled.span`
  color: white;
  margin-left: 4px;
  text-decoration: underline;
`;

export function Checkbox({
  checked,
  onClick,
  label,
  link = "",
  onClickLink = null,
}) {
  return (
    <CheckboxWrapper onClick={onClick}>
      <div>{checked ? <Checked></Checked> : <NotChecked></NotChecked>}</div>
      <Label>{label}</Label>
      <Label_2 onClick={onClickLink}>{link}</Label_2>
    </CheckboxWrapper>
  );
}
