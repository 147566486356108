import React from "react";
import styled from "styled-components";
import { Spinner } from "./Spinner";

const ButtonDarkWrapper = styled.div`
  padding: 12px 32px;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.textColor};
  font-family: Raleway;
  font-size: 18px;
  height: 54px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  display: inline-block;
  user-select: none;
  display: inline-flex;
  align-items: center;
  font-weight: 800;

  &:hover {
    border-color: #434343;
    background-color: #434343;
    color: #fff;
    cursor: ${(props) => (props.waiting === false ? "pointer" : "not-allowed")};
  }
  pointer-events: ${(props) => !props.aktiv && "none"};
`;

const SpinnerWrapper = styled.div`
  margin-right: 10px;
`;

const LabelWrapper = styled.div`
  opacity: ${(props) => (props.waiting ? 0.5 : 1)};
`;

export const ButtonDark = ({
  label,
  onClick,
  waiting = false,
  aktiv = true,
  textColor = "#000000",
  bgColor = "#ffed00",
}) => {
  return (
    <ButtonDarkWrapper
      onClick={() => {
        !waiting && onClick();
      }}
      aktiv={aktiv}
      waiting={waiting}
      textColor={textColor}
      bgColor={bgColor}
    >
      {waiting && (
        <SpinnerWrapper>
          <Spinner></Spinner>
        </SpinnerWrapper>
      )}
      <LabelWrapper waiting={waiting}>{label}</LabelWrapper>
    </ButtonDarkWrapper>
  );
};
