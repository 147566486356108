import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  color: #121212;
  background-color: white;
  cursor: pointer;
  width: 100%;
  min-width: 250px;
  min-height: 400px;
  position: relative;
  visibility: ${(props) => (props.placeholder ? "hidden" : "visible")};
  display: ${(props) => (props.placeholder ? "none" : "block")};

  @media (min-width: 590px) {
    width: 47%;
    visibility: ${(props) => (props.placeholder ? "hidden" : "visible")};
    display: block;
  }

  @media (min-width: 900px) {
    width: 31%;
  }

  @media (min-width: 1600px) {
    width: 23%;
  }
`;

const TextWrapper = styled.div`
  padding: 32px;
`;

const Titel = styled.div`
  font-family: Fjalla One;
  font-size: 26px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.2px;
  margin-bottom: 24px;
`;

const Link = styled.div`
  font-family: Raleway;
  font-size: 12px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
  position: absolute;
  bottom: 24px;
`;

const Teaser = styled.div`
  font-family: Raleway;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.3px;
`;

const Card = ({ bild, titel, link, teaser, url, placeholder = false }) => {
  return (
    <Wrapper
      placeholder={placeholder}
      onClick={() => {
        window.open(url);
      }}
    >
      <div>
        <img src={bild} alt="" style={{ padding: "1px", width: "100%" }} />
      </div>
      <TextWrapper>
        <Titel>{titel}</Titel>
        <Teaser>{teaser}</Teaser>
        <Link>{link}</Link>
      </TextWrapper>
    </Wrapper>
  );
};

export default Card;
