import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import bild from "../images/Teaser_rund.png";

const TeaserContentWrapper = styled.div`
  background-color: #ffed00;
  padding: 0;
  height: 124px;
  margin-top: 0px;
  color: #333333;
  padding-left: 32px;
  padding-right: 8px;

  display: flex;
  align-items: center;
  /* justify-content: space-between; */

  @media (min-width: 520px) {
    padding-left: 32px;
    padding-right: 32px;
  }

  @media (min-width: 750px) {
    /* margin-top: -10vh; */
    margin-top: -148px;
    padding-right: 0px;
  }

  @media (min-width: 900px) {
    font-size: 40px;
    /* padding-top: 48px; */
  }

  @media (min-width: 1200px) {
    /* padding-top: 64px; */
  }

  @media (min-width: 1440px) {
    font-size: 56px;
    /* padding-top: 80px; */
  }
`;

const TeaserTitel = styled.div`
  flex: 0.5;
  font-family: "Fjalla One", sans-serif;
  font-size: 44px;
  font-weight: 800;

  @media (min-width: 520px) {
    font-size: 52px;
  }

  @media (min-width: 750px) {
    font-size: 88px;
  }
`;

const TeaserText = styled.div`
  flex: 2;
  font-family: Raleway;
  font-style: normal;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 20px;
  margin-bottom: 6px;
  margin-top: 6px;
  color: #333333;
  padding-left: 12px;

  @media (min-width: 750px) {
    font-size: 22px;
    letter-spacing: 0.2px;
    line-height: 24px;
  }
`;

const ImageWrapper = styled.div`
  flex: 1;
  margin-top: -44px;
  position: relative;
  display: none;

  @media (min-width: 900px) {
    margin-top: -75px;
    display: block;
  }
  @media (min-width: 1200px) {
    margin-top: -120px;
  }
`;

const Image = styled.img`
  display: none;
  /*   transform: rotate(7deg); */
  /*   right: -70px;
  top: -135px; */
  right: -60px;
  top: -160px;
  position: absolute;

  /*   @media (min-width: 520px) {
    display: block;
    width: 150px;
  } */

  @media (min-width: 900px) {
    display: block;
    width: 340px;
  }
  @media (min-width: 1200px) {
    width: 340px;
  }
`;

const TeaserTextText = styled.div`
  display: block;
  font-weight: 500;
`;

export const TeaserContent = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: "(max-width: 750px)" });
  return (
    <TeaserContentWrapper>
      <TeaserTitel>{t("TeaserTitel")}</TeaserTitel>
      <TeaserText>
        <TeaserTextText>
          {isMobile ? t("TeaserTextTitel_mobile") : t("TeaserTextText")}
        </TeaserTextText>
        <div></div>
      </TeaserText>
      <ImageWrapper>
        <div>
          <Image src={bild} alt="" />
        </div>
      </ImageWrapper>
    </TeaserContentWrapper>
  );
};

export default TeaserContent;
