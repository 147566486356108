import Navigation from "./components/Navigation";
import ImageContainer from "./components/ImageContainer";
import { ButtonDark } from "./components/ButtonDark";
import Intro from "./components/Intro";
import Card from "./components/Card";
import Fragen from "./components/Fragen";
import Video from "./components/Video";
import Formular from "./components/Formular";
import Preise from "./components/Preise";
import Danke from "./components/Danke";
import styled from "styled-components";
import axios from "axios";
import { SpacerProvider } from "react-responsive-spacer";
import TeaserContent from "./components/TeaserContent";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PATTERN_EMAIL, API_URL } from "./helper/constants";
import teaser_lesa from "./images/teaser_lesa.jpg";
import teaser_kaese from "./images/teaser_hoch.jpg";
import teaser_milchbauern from "./images/teaser_milchbauern.jpg";
import FooterContent from "./components/FooterContent";

const FullContainer = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 750px) {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    /* margin-top: -10vh; */
  }

  @media (min-width: 1200px) {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  @media (min-width: 1440px) {
    padding-left: 14rem;
    padding-right: 14rem;
  }

  @media (min-width: 1920px) {
    padding-left: 20rem;
    padding-right: 20rem;
  }

  @media (min-width: 2560) {
    padding-left: 32rem;
    padding-right: 32rem;
  }
`;

const MainContentWrapper = styled.div`
  padding-bottom: 32px;
  padding: 24px 32px;
  padding-top: 0px;
  color: white;
`;

const CardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const ContentWrapper = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  color: black;
`;

const Fehlermeldung = styled.div`
  font-family: futura-pt, sans-serif;
  font-size: 16px;
  font-weight: 800;
  font-style: italic;
  line-height: 28px;
  letter-spacing: 0.3px;
  margin-bottom: 8px;
  margin-top: 8px;
  color: yellow;

  @media (min-width: 750px) {
    font-size: 16px;
  }
`;

const initialData = {
  antwort_1_1: false,
  antwort_1_2: false,
  antwort_1_3: false,
  antwort_2_1: false,
  antwort_2_2: false,
  antwort_2_3: false,
  antwort_3_1: false,
  antwort_3_2: false,
  antwort_3_3: false,
  anrede: "",
  vorname: "",
  nachname: "",
  email: "",
  tag: "",
  monat: "",
  jahr: "",
  teilnahmebedingungen: false,
  alter: false,
};

function App() {
  const [userData, setUserData] = useState(initialData);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [messageHeader, setMessageHeader] = useState("");
  const [teilnahmeAktiv, setTeilnahmeAktiv] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showDanke, setShowDanke] = useState(false);
  const [showPreise, setShowPreise] = useState(false);
  const { t } = useTranslation();

  // Validierung
  const isValidForm = () => {
    if (userData.antwort_1_1 === false && userData.antwort_1_2 === false) {
      setMessageHeader(t("Fragen"));
      setMessage(t("Fragen.err"));
      setError(true);
      return false;
    }

    /*     if (userData.anrede === "") {
      setMessageHeader(t("Anrede"));
      setMessage(t("Anrede.err"));
      setError(true);
      return false;
    } */
    if (userData.vorname === "") {
      setMessageHeader(t("Vorname"));
      setMessage(t("Pflichtfeld.err"));
      setError(true);
      return false;
    }

    if (userData.nachname === "") {
      setMessageHeader(t("Nachname"));
      setMessage(t("Pflichtfeld.err"));
      setError(true);
      return false;
    }

    /*     if (userData.tag === "") {
      setMessageHeader(t("Tag"));
      setMessage(t("Pflichtfeld.err"));
      setError(true);
      return false;
    }
    if (userData.monat === "") {
      setMessageHeader(t("Monat"));
      setMessage(t("Pflichtfeld.err"));
      setError(true);
      return false;
    }
    if (userData.jahr === "") {
      setMessageHeader(t("Jahr"));
      setMessage(t("Pflichtfeld.err"));
      setError(true);
      return false;
    } */
    // Alterscheck
    /*     const dob = userData.jahr + userData.monat + userData.tag;
    const year = Number(dob.substr(0, 4));
    const month = Number(dob.substr(4, 2)) - 1;
    const day = Number(dob.substr(6, 2));
    const today = new Date();
    let age = today.getFullYear() - year;
    if (
      today.getMonth() < month ||
      (today.getMonth() === month && today.getDate() < day)
    ) {
      age--;
    } */

    /*     if (parseInt(age) < 18) {
      setMessageHeader(t("Tag"));
      setMessage(t("Alter.err"));
      setError(true);
      return false;
    } */

    if (!PATTERN_EMAIL.test(userData.email)) {
      setMessageHeader(t("Email"));
      setMessage(t("Email.err"));
      setError(true);
      return false;
    }
    if (userData.alter === false) {
      setMessageHeader(t("tb.alter-titel"));
      setMessage(t("Pflichtfeld.err"));
      setError(true);
      return false;
    }
    if (userData.teilnahmebedingungen === false) {
      setMessageHeader(t("tb.teil.2"));
      setMessage(t("Pflichtfeld.err"));
      setError(true);
      return false;
    }

    return true;
  };

  // Daten in mysql Datenbank schreiben
  const sendDataToDB = async () => {
    let base_url = "";
    let sapError = "";
    if (
      window.location.hostname === "localhost" ||
      window.location.hostname === "127.0.0.1"
    ) {
      base_url = API_URL.URL_LOCALE;
    } else {
      base_url = API_URL.URL_REMOTE;
    }
    const response = await axios.get(base_url + "store.php", {
      params: { ...userData },
    });
    setLoading(false);
    setTeilnahmeAktiv(false);
    return response;
  };

  const handleTeilnehmenClick = async (event) => {
    if (isValidForm()) {
      setLoading(true);
      const result = await sendDataToDB();
      if (result.data.error === "none") {
        setShowDanke(true);
        /*         setShowAnleitung(false);
        setShowForm(false);
        setShowFragen(false); */
        /*         dankeRef.current.scrollIntoView({
          behavior: "smooth",
        }); */
      }
    }
  };

  const onSelectOption = (state) => {
    console.log("onSelectOption");
    console.log(state);
    setUserData({
      ...userData,
      antwort_1_1: state.antwort_1_1,
      antwort_1_2: state.antwort_1_2,
      antwort_1_3: state.antwort_1_3,
      antwort_2_1: state.antwort_2_1,
      antwort_2_2: state.antwort_2_2,
      antwort_2_3: state.antwort_2_3,
      antwort_3_1: state.antwort_3_1,
      antwort_3_2: state.antwort_3_2,
      antwort_3_3: state.antwort_3_3,
    });
  };

  const onChangeForm = (state) => {
    console.log("onChangeForm");
    console.log(userData);
    console.log(state);
    setUserData({
      ...userData,
      ...state,
    });
  };

  const handleClickPreise = () => {
    setShowPreise(true);
  };

  const handlePreiseBackLinkClick = () => {
    setShowPreise(false);
  };

  const handleHotelLinkClick = () => {
    window.open(t("Inlain_URL"));
  };

  return (
    <div>
      <SpacerProvider
        breakpoints={{
          mb: "0px",
          sm: "520px",
          md: "750px",
          lg: "900px",
          xl: "1280px",
          xxl: "1440px",
        }}
      >
        <Navigation></Navigation>
        <ImageContainer></ImageContainer>
        <FullContainer>
          <ContentWrapper>
            <TeaserContent></TeaserContent>
            {showPreise ? (
              <>
                <Preise
                  handlePreiseBackLinkClick={handlePreiseBackLinkClick}
                  handleHotelLinkClick={handleHotelLinkClick}
                ></Preise>
              </>
            ) : (
              <>
                {showDanke && <Danke></Danke>}
                {!showDanke && (
                  <>
                    <Intro handleClickPreise={handleClickPreise}></Intro>
                    <Fragen onSelectOption={onSelectOption}></Fragen>
                    {/*                     <MainContentWrapper>
                      <Video></Video>
                    </MainContentWrapper> */}
                    <Formular onChangeForm={onChangeForm}></Formular>
                    <MainContentWrapper>
                      {error && (
                        <Fehlermeldung>
                          {messageHeader}: {message}
                        </Fehlermeldung>
                      )}
                      <ButtonDark
                        label={t("Button_teilnehmen")}
                        onClick={handleTeilnehmenClick}
                        waiting={loading}
                        aktiv={teilnahmeAktiv}
                        bgColor={"#ffed00"}
                        textColor={"#000000"}
                      ></ButtonDark>
                    </MainContentWrapper>
                  </>
                )}
                <MainContentWrapper>
                  <CardWrapper>
                    <Card
                      bild={teaser_lesa}
                      titel={t("Card_About_Titel")}
                      teaser={""}
                      link={t("Card_About_Link")}
                      url={t("About_URL")}
                    ></Card>
                    <Card
                      bild={teaser_kaese}
                      titel={t("Card_Kaese_Titel")}
                      teaser={""}
                      link={t("Card_Kaese_Link")}
                      url={t("Kaese_URL")}
                    ></Card>
                    <Card
                      bild={null}
                      titel={t("Card_Insta_Titel")}
                      teaser={t("Card_Insta_Teaser")}
                      link={t("Card_Insta_Link")}
                      url={t("Insta_URL")}
                    ></Card>
                    {/*                     <Card
                      bild={null}
                      titel={t("Card_FB_Titel")}
                      teaser={t("Card_FB_Teaser")}
                      link={t("Card_FB_Link")}
                      url={t("FB_URL")}
                    ></Card> */}
                    <Card
                      bild={teaser_milchbauern}
                      titel={t("Card_Milchbauern_Titel")}
                      teaser={t("Card_Milchbauern_Teaser")}
                      link={t("Card_Milchbauern_Link")}
                      url={t("Milchbauern_URL")}
                    ></Card>
                    {/*                     <Card
                      placeholder={true}
                      bild={null}
                      titel={""}
                      teaser={""}
                      link={""}
                      url={""}
                    ></Card> */}
                  </CardWrapper>
                </MainContentWrapper>
              </>
            )}
          </ContentWrapper>
        </FullContainer>
        <FooterContent></FooterContent>
      </SpacerProvider>
    </div>
  );
}

export default App;
