import React from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import w_1920 from "../images/LESA Jogurt Fruehling 23 3840x2160px.jpg";
import mobile_de from "../images/LESA Jogurt Fruehling 23 3840x2160px.jpg";
import mobile_fr from "../images/LESA Jogurt Fruehling 23 3840x2160px.jpg";

const ImageContainerWrapper = styled.div`
  height: calc(50vh - 72px);
  width: 100%;
  display: relative;
  background-color: #fff;

  @media (min-width: 750px) {
    height: calc(80vh - 160px);
  }
`;

const Bild = styled.img`
  object-fit: cover;
  object-position: bottom;
  height: calc(50vh - 72px);
  width: 100%;

  @media (min-width: 750px) {
    height: calc(80vh - 160px);
  }
`;

export const ImageContainer = () => {
  const { i18n } = useTranslation();

  const isMobileDevice = useMediaQuery({
    query: "(max-device-width: 519px)",
  });

  return (
    <ImageContainerWrapper>
      <Bild
        src={
          isMobileDevice
            ? i18n.language === "de"
              ? mobile_de
              : mobile_fr
            : w_1920
        }
        alt=""
        data-object-fit="cover"
      ></Bild>
    </ImageContainerWrapper>
  );
};

export default ImageContainer;
