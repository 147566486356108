//https://gist.github.com/knowbody/578b35164b69e867ed4913423f6bed30

import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

const StyledSpinner = styled.svg`
  animation: rotate 1s linear infinite;
  width: 20px;
  height: 20px;

  & .path {
    stroke: #666;
    stroke-width: 6px;
    width: 20px;
    height: 20px;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

export const Spinner = () => (
  <StyledSpinner viewBox="0 0 50 50">
    <circle
      className="path"
      cx="25"
      cy="25"
      r="20"
      fill="none"
      strokeWidth="2"
    />
  </StyledSpinner>
);
