// Die RegEx Pattern für die Validierung des Formulars
export const PATTERN_EMAIL = new RegExp(
  // eslint-disable-next-line
  /* /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ */
  /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/
);

/* export const PATTERN_TELEFON = new RegExp("^\\+(?:[0-9] ?){6,28}[0-9]$"); */
export const PATTERN_TELEFON = new RegExp("^\\+[1-9]{1}[0-9]{6,28}$");

export const PATTERN_PLZ = new RegExp(/^[0-9]{4}$/);

export const API_URL = {
  URL_LOCALE: "http://emmi-lesa/",
  URL_REMOTE: "https://www.lesa-win.ch/php/",
};
